import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    component: () => import("@/views/Login.vue"),
    path: "/login",
    name: "Login",
    meta: {
      logged: false,
      title: "base.routes.login.main",
      icon: "users",
    },
  },
  {
    path: "/users",
    alias: "/",
    name: "UsersView",
    meta: {
      logged: true,
      title: "base.routes.users.main",
      target: "UsersList",
      icon: "users",
    },
    children: [
      {
        component: () => import("@/views/UsersList"),
        path: "/users/:action?",
        name: "UsersList",
        meta: {
          logged: true,
          title: "base.routes.users.list",
          buttons: [
            {
              to: "UsersList",
              params: {
                action: "reports",
              },
              title: "base.routes.users.reports",
              icon: "file-attachment",
            },
            {
              to: "UsersList",
              params: {
                action: "generator",
              },
              title: "base.routes.users.generator",
              icon: "file-lock",
            },
            {
              to: "UsersForm",
              title: "base.routes.users.form",
              icon: "plus-square",
            },
          ],
        },
      },
      {
        component: () => import("@/views/UsersForm.vue"),
        path: "/user",
        name: "UsersForm",
        meta: {
          logged: true,
          title: "base.routes.users.form",
        },
      },
      {
        component: () => import("@/views/UsersEdit.vue"),
        path: "/user/:id",
        name: "UsersEdit",
        meta: {
          logged: true,
          title: "base.routes.users.edit",
        },
      },
    ],
  },
  {
    path: "/companies",
    name: "CompaniesView",
    meta: {
      logged: true,
      title: "base.routes.companies.main",
      target: "CompaniesList",
      icon: "briefcase",
    },
    children: [
      {
        component: () => import("@/views/CompaniesList.vue"),
        path: "/companies",
        name: "CompaniesList",
        meta: {
          logged: true,
          title: "base.routes.companies.list",
          buttons: [
            {
              to: "CompaniesForm",
              title: "base.routes.companies.form",
              icon: "plus-square",
            },
          ],
        },
      },
      {
        component: () => import("@/views/CompaniesForm.vue"),
        path: "/company/:id?",
        name: "CompaniesForm",
        meta: {
          logged: true,
          title: "base.routes.companies.form",
        },
      },
      {
        component: () => import("@/views/UsersForm.vue"),
        path: "/company/:idCompany/employee",
        name: "EmployeesForm",
        meta: {
          logged: true,
          title: "base.routes.employees.form",
        },
      },
      {
        component: () => import("@/views/EmployeesList.vue"),
        path: "/company/:id/employees",
        name: "EmployeesList",
        meta: {
          logged: true,
          title: "base.routes.employees.list",
          buttons: [
            {
              to: "EmployeesForm",
              title: "base.routes.employees.form",
              icon: "plus-square",
              paramsPatterns: { idCompany: "id" },
              params: {
                idCompany: ":id",
              },
            },
          ],
        },
      },
    ],
  },
  {
    path: "/paths",
    name: "PathsView",
    meta: {
      logged: true,
      title: "base.routes.paths.main",
      target: "PathsList",
      icon: "bezier-curve",
    },
    children: [
      {
        component: () => import("@/views/PathsList.vue"),
        path: "/paths",
        name: "PathsList",
        meta: {
          logged: true,
          title: "base.routes.paths.list",
          buttons: [
            {
              to: "PathsAdd",
              title: "base.routes.paths.add",
              icon: "plus-square",
            },
          ],
        },
      },
      {
        component: () => import("@/views/PathsForm.vue"),
        path: "/path",
        name: "PathsAdd",
        meta: {
          logged: true,
          title: "base.routes.paths.add",
        },
      },
      {
        component: () => import("@/views/PathsForm.vue"),
        path: "/path/:id",
        name: "PathsEdit",
        meta: {
          logged: true,
          title: "base.routes.paths.edit",
        },
      },
    ],
  },
  {
    path: "/exams",
    name: "ExamsView",
    meta: {
      logged: true,
      title: "base.routes.exams.main",
      target: "ExamsList",
      icon: "certificate",
    },
    children: [
      {
        component: () => import("@/views/ExamsList.vue"),
        path: "",
        name: "ExamsList",
        meta: {
          logged: true,
          title: "base.routes.exams.list",
          buttons: [
            {
              to: "ExamsForm",
              title: "base.routes.exams.form",
              icon: "plus-square",
            },
          ],
        },
      },
      {
        component: () => import("@/views/ExamsForm.vue"),
        path: "/exam/:id?",
        name: "ExamsForm",
        meta: {
          logged: true,
          title: "base.routes.exams.form",
        },
      },
      {
        component: () => import("@/views/ExamsResults.vue"),
        path: "/results/:id",
        name: "ExamsResults",
        meta: {
          logged: true,
          title: "base.routes.exams.results",
        },
      },
    ],
  },
  {
    path: "/quizzes",
    name: "QuizzesView",
    meta: {
      logged: true,
      title: "base.routes.quizzes.main",
      target: "QuizzesList",
      icon: "clipboard",
      buttons: [
        {
          to: "QuizzesForm",
          title: "base.routes.quizzes.form",
          icon: "plus-square",
        },
      ],
    },
    children: [
      {
        component: () => import("@/views/QuizzesList.vue"),
        path: "/quizzes",
        name: "QuizzesList",
        meta: {
          logged: true,
          title: "base.routes.quizzes.list",
        },
      },
      {
        component: () => import("@/views/QuizzesForm.vue"),
        path: "/quiz/:id?",
        name: "QuizzesForm",
        meta: {
          logged: true,
          title: "base.routes.quizzes.form",
        },
      },
    ],
  },
  {
    path: "/vouchers",
    name: "VouchersView",
    meta: {
      logged: true,
      title: "base.routes.vouchers.main",
      target: "VouchersList",
      icon: "sale",
      buttons: [
        {
          to: "VouchersForm",
          title: "base.routes.vouchers.form",
          icon: "plus-square",
        },
      ],
    },
    children: [
      {
        component: () => import("@/views/VouchersList.vue"),
        path: "/vouchers",
        name: "VouchersList",
        meta: {
          logged: true,
          title: "base.routes.vouchers.list",
        },
      },
      {
        component: () => import("@/views/VouchersForm.vue"),
        path: "/voucher/:id?",
        name: "VouchersForm",
        meta: {
          logged: true,
          title: "base.routes.vouchers.form",
        },
      },
    ],
  },
  {
    path: "/videos",
    name: "VideosView",
    meta: {
      logged: true,
      title: "base.routes.videos.main",
      target: "VideosList",
      icon: "film",
    },
    children: [
      {
        component: () => import("@/views/VideosList.vue"),
        path: "",
        name: "VideosList",
        meta: {
          logged: true,
          title: "base.routes.videos.list",
          buttons: [
            {
              to: "VideosForm",
              title: "base.routes.videos.form",
              icon: "plus-square",
            },
          ],
        },
      },
      {
        component: () => import("@/views/VideosForm.vue"),
        path: "/video",
        name: "VideosForm",
        meta: {
          logged: true,
          title: "base.routes.videos.form",
        },
      },
      {
        component: () => import("@/views/VideosForm.vue"),
        path: "/video/:id",
        name: "VideoEdit",
        meta: {
          logged: true,
          title: "base.routes.videos.edit",
        },
      },
    ],
  },
  {
    path: "/series",
    name: "SeriesView",
    meta: {
      logged: true,
      title: "base.routes.series.main",
      target: "SeriesList",
      icon: "layers-three",
    },
    children: [
      {
        component: () => import("@/views/SeriesList.vue"),
        path: "",
        name: "SeriesList",
        meta: {
          logged: true,
          title: "base.routes.series.list",
          buttons: [
            {
              to: "SerieAdd",
              title: "base.routes.series.form",
              icon: "plus-square",
            },
          ],
        },
      },
      {
        component: () => import("@/views/SeriesForm.vue"),
        path: "/serie",
        name: "SerieAdd",
        meta: {
          logged: true,
          title: "base.routes.series.form",
        },
      },
      {
        component: () => import("@/views/SeriesForm.vue"),
        path: "/serie/:id",
        name: "SerieEdit",
        meta: {
          logged: true,
          title: "base.routes.series.edit",
        },
      },
    ],
  },
  {
    path: "/categories",
    name: "CategoriesView",
    meta: {
      logged: true,
      title: "base.routes.categories.main",
      target: "CategoriesList",
      icon: "folder",
    },
    children: [
      {
        component: () => import("@/views/CategoriesList.vue"),
        path: "/categories/:action?",
        name: "CategoriesList",
        meta: {
          logged: true,
          title: "base.routes.categories.list",
          buttons: [
            {
              to: "CategoriesList",
              params: {
                action: "order",
              },
              title: "base.routes.categories.order",
              icon: "switch-vertical-02",
            },
            {
              to: "CategoriesForm",
              title: "base.routes.categories.form",
              icon: "plus-square",
            },
          ],
        },
      },
      {
        component: () => import("@/views/CategoriesForm.vue"),
        path: "/category/:id?",
        name: "CategoriesForm",
        meta: {
          logged: true,
          title: "base.routes.categories.form",
        },
      },
    ],
  },
  {
    component: () => import("@/views/AuthorsList.vue"),
    path: "/authors",
    name: "AuthorsView",
    meta: {
      logged: true,
      title: "base.routes.authors.main",
      target: "AuthorsList",
      icon: "user-circle",
    },
    children: [
      {
        component: () => import("@/views/AuthorsList.vue"),
        path: "",
        name: "AuthorsList",
        meta: {
          logged: true,
          title: "base.routes.authors.list",
          buttons: [
            {
              to: "AuthorAdd",
              title: "base.routes.authors.form",
              icon: "plus-square",
            },
          ],
        },
      },
    ],
  },
  {
    component: () => import("@/views/AuthorsForm.vue"),
    path: "/author",
    name: "AuthorAdd",
    meta: {
      logged: true,
      title: "base.routes.authors.form",
    },
  },
  {
    component: () => import("@/views/AuthorsForm.vue"),
    path: "/author/:id",
    name: "AuthorEdit",
    meta: {
      logged: true,
      title: "base.routes.authors.edit",
    },
  },
  // {
  //   component: () => import("@/views/AnnouncementsList.vue"),
  //   path: "/announcements",
  //   name: "AnnouncementsView",
  //   meta: {
  //     logged: true,
  //     title: "base.routes.announcements.main",
  //     target: "AnnouncementsList",
  //     icon: "play-circle",
  //   },
  //   children: [
  //     {
  //       component: () => import("@/views/AnnouncementsList.vue"),
  //       path: "",
  //       name: "AnnouncementsList",
  //       meta: {
  //         logged: true,
  //         title: "base.routes.announcements.list",
  //         buttons: [
  //           {
  //             to: "AnnouncementAdd",
  //             title: "base.routes.announcements.form",
  //             icon: "plus-square",
  //           },
  //         ],
  //       },
  //     },
  //   ],
  // },
  // {
  //   component: () => import("@/views/AnnouncementsForm.vue"),
  //   path: "/announcement/:id",
  //   name: "AnnouncementEdit",
  //   meta: {
  //     logged: true,
  //     title: "base.routes.announcements.edit",
  //   },
  // },
  // {
  //   component: () => import("@/views/AnnouncementsForm.vue"),
  //   path: "/announcement",
  //   name: "AnnouncementAdd",
  //   meta: {
  //     logged: true,
  //     title: "base.routes.announcements.form",
  //   },
  // },
  {
    component: () => import("@/views/NotificationsView.vue"),
    path: "/notifications",
    name: "NotificationsView",
    meta: {
      logged: true,
      title: "base.routes.notifications.form",
      icon: "bell-ringing-01",
    },
  },
  {
    path: "/accredibles",
    name: "AccredibleView",
    meta: {
      logged: true,
      title: "base.routes.accredible.main",
      target: "AccredibleList",
      icon: "check-circle",
    },
    children: [
      {
        component: () => import("@/views/AccredibleList.vue"),
        path: "/accredibles",
        name: "AccredibleList",
        meta: {
          logged: true,
          title: "base.routes.accredible.list",
          buttons: [
            {
              to: "AccredibleForm",
              title: "base.routes.accredible.form",
              icon: "plus-square",
            },
          ],
        },
      },
      {
        component: () => import("@/views/AccredibleForm.vue"),
        path: "/accredible/:id?",
        name: "AccredibleForm",
        meta: {
          logged: true,
          title: "base.routes.accredible.form",
        },
      },
    ],
  },
  {
    path: "/accredibles",
    name: "AccredibleView",
    meta: {
      logged: true,
      title: "base.routes.accredible.main",
      target: "AccredibleList",
      icon: "check-circle",
    },
    children: [
      {
        component: () => import("@/views/AccredibleList.vue"),
        path: "/accredibles",
        name: "AccredibleList",
        meta: {
          logged: true,
          title: "base.routes.accredible.list",
          buttons: [
            {
              to: "AccredibleForm",
              title: "base.routes.accredible.form",
              icon: "plus-square",
            },
          ],
        },
      },
      {
        component: () => import("@/views/AccredibleForm.vue"),
        path: "/accredible/:id?",
        name: "AccredibleForm",
        meta: {
          logged: true,
          title: "base.routes.accredible.form",
        },
      },
    ],
  },
  {
    path: "/cohort",
    name: "CohortView",
    meta: {
      logged: true,
      title: "base.routes.cohort.main",
      target: "CohortModulesList",
      icon: "cohort",
    },
    children: [
      {
        component: () => import("@/views/CohortList.vue"),
        path: "modules",
        name: "CohortModulesList",
        meta: {
          logged: true,
          title: "base.routes.cohort.list",
          buttons: [
            {
              to: "CohortModuleAdd",
              title: "base.routes.cohort.addModule",
              icon: "plus-square",
            },
          ],
        },
      },
      {
        component: () => import("@/views/CohortModuleForm.vue"),
        path: "modules/add",
        name: "CohortModuleAdd",
        meta: {
          logged: true,
          title: "base.routes.cohort.addModule",
        },
      },
      {
        component: () => import("@/views/CohortModuleForm.vue"),
        path: "module/:id",
        name: "CohortModuleEdit",
        meta: {
          logged: true,
          title: "base.routes.cohort.editModule",
        },
      },
      {
        component: () => import("@/views/CohortMaterialForm.vue"),
        path: "module/:moduleId/materials/add",
        name: "CohortMaterialAdd",
        meta: {
          logged: true,
          title: "base.routes.cohort.addMaterial",
        },
      },
      {
        component: () => import("@/views/CohortMaterialForm.vue"),
        path: "module/:moduleId/material/:id",
        name: "CohortMaterialEdit",
        meta: {
          logged: true,
          title: "base.routes.cohort.editMaterial",
        },
      },
    ],
  },
  {
    path: "/programs",
    name: "DpProgramsView",
    meta: {
      logged: true,
      title: "base.routes.programs.main",
      target: "DpProgramsList",
      icon: "user-circle",
    },
    children: [
      {
        component: () => import("@/views/DpProgramsList.vue"),
        path: "",
        name: "DpProgramsList",
        meta: {
          logged: true,
          title: "base.routes.programs.list",
          buttons: [
            {
              to: "DpProgramsForm",
              title: "base.routes.programs.addProgram",
              icon: "plus-square",
            },
          ],
        },
      },
      {
        component: () => import("@/views/DpProgramsForm.vue"),
        path: "/program/:id?",
        name: "DpProgramsForm",
        meta: {
          logged: true,
          title: "base.routes.programs.form",
        },
      },
      {
        component: () => import("@/views/DpProgramSectionsList.vue"),
        path: "/program/:id/sections",
        name: "SectionsList",
        meta: {
          logged: true,
          title: "base.routes.sections.list",
          buttons: [
            {
              to: "DpProgramSectionForm",
              title: "base.routes.programs.addSection",
              icon: "plus-square",
              paramsPatterns: { programid: "id" },
              params: {
                programid: ":id",
              },
            },
          ],
        },
      },
      {
        component: () => import("@/views/DpProgramSectionForm.vue"),
        path: "/program/:programid/section/:id?",
        name: "DpProgramSectionForm",
        props: true,
        meta: {
          logged: true,
          title: "base.routes.programs.section.form",
        },
      },
      {
        component: () => import("@/views/DpProgramModulesList.vue"),
        path: "/program/section/:id/modules",
        name: "ModulesList",
        meta: {
          logged: true,
          title: "base.routes.modules.list",
          buttons: [
            {
              to: "DpProgramModuleForm",
              title: "base.routes.programs.addModule",
              icon: "plus-square",
              paramsPatterns: { sectionid: "id" },
              params: {
                sectionid: ":id",
              },
            },
          ],
        },
      },
      {
        component: () => import("@/views/DpProgramModulesForm.vue"),
        path: "/program/section/:sectionid/module/:id?",
        name: "DpProgramModuleForm",
        props: true,
        meta: {
          logged: true,
          title: "base.routes.programs.module.form",
        },
      },
      {
        component: () => import("@/views/DpProgramGroupList.vue"),
        path: "/program/:id/groups",
        name: "GroupsList",
        meta: {
          logged: true,
          title: "base.routes.groups.list",
          buttons: [
            {
              to: "GroupsForm",
              title: "base.routes.programs.addGroup",
              icon: "plus-square",
              paramsPatterns: { programid: "id" },
              params: {
                programid: ":id",
              },
            },
          ],
        },
      },
      {
        component: () => import("@/views/DpProgramGroupForm.vue"),
        path: "/program/groups/:programid/:id?",
        name: "GroupsForm",
        props: true,
        meta: {
          logged: true,
          title: "base.routes.groups.form",
        },
      },
      {
        component: () => import("@/views/DpProgramGroupUsersList.vue"),
        path: "/program/:programid/:id/users",
        name: "GroupUsersList",
        meta: {
          logged: true,
          title: "base.routes.groups.list",
        },
      },
      {
        component: () => import("@/views/DpProgramGroupUserForm.vue"),
        path: "/program/group/:programid/:groupid/users/:id?",
        name: "DpProgramGroupUserForm",
        props: true,
        meta: {
          logged: true,
          title: "base.routes.programs.groups.form",
        },
      },
      {
        component: () => import("@/views/DpProgramHomeworksList.vue"),
        path: "/program/module/:moduleid/:id/homeworks",
        name: "ProgramHomeworks",
        meta: {
          logged: true,
          title: "base.routes.homeworks.list",
          buttons: [
            {
              to: "DPProgramHomeworkForm",
              title: "base.routes.programs.addHomework",
              icon: "plus-square",
              paramsPatterns: { moduleid: "id", sectionid: "moduleid" },
              params: {
                moduleid: ":id",
                sectionid: ":moduleid",
              },
            },
          ],
        },
      },
      {
        component: () => import("@/views/DpProgramHomeworkForm.vue"),
        path: "/program/module/homework/:sectionid/:moduleid/:id?",
        name: "DPProgramHomeworkForm",
        props: true,
        meta: {
          logged: true,
          title: "base.routes.programs.section.form",
        },
      },
      {
        component: () => import("@/views/DpProgramPreworksList.vue"),
        path: "/program/module/:moduleid/:id/preworks",
        name: "ProgramPreworks",
        meta: {
          logged: true,
          title: "base.routes.preworks.list",
          buttons: [
            {
              to: "DpProgramPreworkForm",
              title: "base.routes.programs.addprework",
              icon: "plus-square",
              paramsPatterns: { moduleid: "id", sectionid: "moduleid" },
              params: {
                moduleid: ":id",
                sectionid: ":moduleid",
              },
            },
          ],
        },
      },
      {
        component: () => import("@/views/DpProgramPreworkForm.vue"),
        path: "/program/module/:sectionid/:moduleid/prework/:id?",
        name: "DpProgramPreworkForm",
        props: true,
        meta: {
          logged: true,
          title: "base.routes.programs.section.form",
        },
      },
      {
        component: () => import("@/views/DpProgramAssessmentsList.vue"),
        path: "/program/module/:moduleid/:id/assessments",
        name: "ProgramAssessments",
        meta: {
          logged: true,
          title: "base.routes.assessments.list",
          buttons: [
            {
              to: "DpProgramAssessmentForm",
              title: "base.routes.programs.addassessment",
              icon: "plus-square",
            },
          ],
        },
      },
      {
        component: () => import("@/views/DpProgramAssessmentForm.vue"),
        path: "/program/module/assessment/:id?",
        name: "DpProgramAssessmentForm",
        meta: {
          logged: true,
          title: "base.routes.programs.section.form",
        },
      },
      {
        component: () =>
          import("@/views/DpProgramAssessmentsCategoriesList.vue"),
        path: "/program/module/assessment/:id/categories",
        name: "DpProgramAssessmentsCategoriesList",
        meta: {
          logged: true,
          title: "base.routes.programs.section.form",
        },
      },
      {
        component: () =>
          import("@/views/DpProgramAssessmentsQuestionsList.vue"),
        path: "/program/module/assessment/:id/questions",
        name: "DpProgramAssessmentsQuestionsList",
        meta: {
          logged: true,
          title: "base.routes.programs.section.form",
        },
      },
      {
        component: () => import("@/views/DpProgramLiveWidgetsList.vue"),
        path: "/program/module/:moduleid/:id/livewidgets",
        name: "ProgramLiveWidgets",
        meta: {
          logged: true,
          title: "base.routes.widgets.list",
          buttons: [
            {
              to: "LiveWidgetForm",
              title: "base.routes.programs.addLiveWebinar",
              icon: "plus-square",
              paramsPatterns: { moduleid: "id", sectionid: "moduleid" },
              params: {
                moduleid: ":id",
                sectionid: ":moduleid",
              },
            },
          ],
        },
      },
      {
        component: () => import("@/views/DpProgramLiveWidgetsForm.vue"),
        path: "/program/module/livewidget/:sectionid/:moduleid/:id?",
        name: "LiveWidgetForm",
        props: true,
        meta: {
          logged: true,
          title: "base.routes.programs.section.form",
        },
      },
      {
        component: () => import("@/views/DpProgramKickoffList.vue"),
        path: "/program/module/:moduleid/:id/kickoffs",
        name: "ProgramKickoffs",
        meta: {
          logged: true,
          title: "base.routes.kickoffs.list",
          buttons: [
            {
              to: "KickoffForm",
              title: "base.routes.programs.addKickoff",
              icon: "plus-square",
              paramsPatterns: { moduleid: "id", sectionid: "moduleid" },
              params: {
                moduleid: ":id",
                sectionid: ":moduleid",
              },
            },
          ],
        },
      },
      {
        component: () => import("@/views/DpKickoffForm.vue"),
        path: "/program/module/kickoff/:sectionid/:moduleid/:id?",
        name: "KickoffForm",
        props: true,
        meta: {
          logged: true,
          title: "base.routes.programs.section.form",
        },
      },
      {
        component: () => import("@/views/DpProgramPrecertificatesList.vue"),
        path: "/program/:id/precertificates",
        name: "PrecertificatesList",
        meta: {
          logged: true,
          title: "base.routes.precertificates.list",
          buttons: [
            {
              to: "PrecertificatesForm",
              title: "base.routes.programs.addPrecertificate",
              icon: "plus-square",
              paramsPatterns: { programid: "id" },
              params: {
                programid: ":id",
              },
            },
          ],
        },
      },
      {
        component: () => import("@/views/DpProgramPrecertificatesForm.vue"),
        path: "/program/precertificates/:programid/:id?",
        name: "PrecertificatesForm",
        props: true,
        meta: {
          logged: true,
          title: "base.routes.precertificates.form",
        },
      },
      {
        component: () => import("@/views/DpProgramScheduleList.vue"),
        path: "/program/module/:moduleid/:id/schedules",
        name: "SchedulesList",
        meta: {
          logged: true,
          title: "base.routes.schedules.list",
          buttons: [
            {
              to: "ScheduleForm",
              title: "base.routes.programs.addSchedule",
              icon: "plus-square",
              paramsPatterns: { moduleid: "id", sectionid: "moduleid" },
              params: {
                moduleid: ":id",
                sectionid: ":moduleid",
              },
            },
          ],
        },
      },
      {
        component: () => import("@/views/DpProgramScheduleForm.vue"),
        path: "/program/module/schedule/:sectionid/:moduleid/:id?",
        name: "ScheduleForm",
        props: true,
        meta: {
          logged: true,
          title: "base.routes.programs.section.form",
        },
      },
      {
        component: () => import("@/views/DpReflectionsList.vue"),
        path: "/program/module/:moduleid/:id/reflections",
        name: "ReflectionsList",
        meta: {
          logged: true,
          title: "base.routes.schedules.list",
          buttons: [
            {
              to: "ReflectionForm",
              title: "base.routes.programs.addReflection",
              icon: "plus-square",
              paramsPatterns: { moduleid: "id", sectionid: "moduleid" },
              params: {
                moduleid: ":id",
                sectionid: ":moduleid",
              },
            },
          ],
        },
      },
      {
        component: () => import("@/views/DpReflectionForm.vue"),
        path: "/program/module/reflections/:sectionid/:moduleid/:id?",
        name: "ReflectionForm",
        props: true,
        meta: {
          logged: true,
          title: "base.routes.reflections.form",
        },
      },
      {
        component: () => import("@/views/DpProgramTemplatesList.vue"),
        path: "/program/module/:moduleid/:id/templates",
        name: "TemplatesList",
        meta: {
          logged: true,
          title: "base.routes.schedules.list",
          buttons: [
            {
              to: "TemplatesForm",
              title: "base.routes.programs.addTemplate",
              icon: "plus-square",
              paramsPatterns: { moduleid: "id", sectionid: "moduleid" },
              params: {
                moduleid: ":id",
                sectionid: ":moduleid",
              },
            },
          ],
        },
      },
      {
        component: () => import("@/views/DpProgramTemplatesForm.vue"),
        path: "/program/module/templates/:sectionid/:moduleid/:id?",
        name: "TemplatesForm",
        props: true,
        meta: {
          logged: true,
          title: "base.routes.murals.form",
        },
      },
      {
        component: () => import("@/views/DpProgramModuleFileForm.vue"),
        path: "/program/module/materials/:sectionid/:moduleid/:id?",
        name: "ModuleFileForm",
        props: true,
        meta: {
          logged: true,
          title: "base.routes.materials.form",
        },
      },
      {
        component: () => import("@/views/DpProgramModuleFilesList.vue"),
        path: "/program/module/:moduleid/:id/materials",
        name: "FilesList",
        meta: {
          logged: true,
          title: "base.routes.materials.list",
        },
      },
      {
        component: () => import("@/views/DpProgramAssessmentModuleForm.vue"),
        path: "/program/module/assessment-module/:sectionid/:moduleid/:id?",
        name: "AssessmentModuleForm",
        props: true,
        meta: {
          logged: true,
          title: "base.routes.assessmentmodule.form",
        },
      },
      {
        component: () => import("@/views/DpProgramAssessmentModuleList.vue"),
        path: "/program/module/:moduleid/:id/module-assessments",
        name: "AssessmentsModuleList",
        meta: {
          logged: true,
          title: "base.routes.assessmentsmodule.list",
        },
      },
    ],
  },
  {
    path: "/wall",
    name: "WallView",
    meta: {
      logged: true,
      title: "base.routes.wall.main",
      target: "WallList",
      icon: "check-circle",
    },
    children: [
      {
        component: () => import("@/views/WallList.vue"),
        path: "/walls",
        name: "WallList",
        meta: {
          logged: true,
          title: "base.routes.wall.list",
          buttons: [
            {
              to: "WallForm",
              title: "base.routes.wall.form",
              icon: "plus-square",
            },
          ],
        },
      },
      {
        component: () => import("@/views/WallForm.vue"),
        path: "/wall/:id?",
        name: "WallForm",
        meta: {
          logged: true,
          title: "base.routes.wall.form",
        },
      },
    ],
  },
  // MATERIAL ZONE
  {
    path: "/material-zone",
    name: "MaterialZoneView",
    meta: {
      logged: true,
      title: "base.routes.materialZone.main",
      target: "MaterialZoneElements",
      icon: "material-zone",
    },
    children: [
      {
        component: () => import("@/views/MaterialZoneElementsList.vue"),
        path: "elements",
        name: "MaterialZoneElements",
        meta: {
          logged: true,
          title: "base.routes.materialZone.elements",
          buttons: [
            {
              to: "MaterialZoneElementAdd",
              title: "base.routes.materialZone.addElement",
              icon: "plus-square",
            },
          ],
        },
      },
      {
        component: () => import("@/views/MaterialZoneElementForm.vue"),
        path: "elements/add",
        name: "MaterialZoneElementAdd",
        meta: {
          logged: true,
          title: "base.routes.materialZone.addElement",
        },
      },
      {
        component: () => import("@/views/MaterialZoneElementForm.vue"),
        path: "elements/:id",
        name: "MaterialZoneElementEdit",
        meta: {
          logged: true,
          title: "base.routes.materialZone.editElement",
        },
      },

      {
        component: () => import("@/views/MaterialZoneSlidesList.vue"),
        path: "slides",
        name: "MaterialZoneSlides",
        meta: {
          logged: true,
          title: "base.routes.materialZone.slides",
          buttons: [
            {
              to: "MaterialZoneSlideAdd",
              title: "base.routes.materialZone.addSlide",
              icon: "plus-square",
            },
          ],
        },
      },
      {
        component: () => import("@/views/MaterialZoneSlideForm.vue"),
        path: "slides/add",
        name: "MaterialZoneSlideAdd",
        meta: {
          logged: true,
          title: "base.routes.materialZone.addSlide",
        },
      },
      {
        component: () => import("@/views/MaterialZoneSlideForm.vue"),
        path: "slides/:id",
        name: "MaterialZoneSlideEdit",
        meta: {
          logged: true,
          title: "base.routes.materialZone.editSlide",
        },
      },
    ],
  },
  // END MATERIAL ZONE
  //livesession slider
  {
    path: "/live-sessions-sliders",
    name: "LiveSessionView",
    meta: {
      logged: true,
      title: "base.routes.livesessions.main",
      target: "LiveSessionList",
      icon: "home",
    },
    children: [
      {
        component: () => import("@/views/LiveSessionList.vue"),
        path: "",
        name: "LiveSessionList",
        meta: {
          logged: true,
          title: "base.routes.livesessions.main",
          buttons: [
            {
              to: "LiveSession",
              title: "base.routes.livesessions.main",
              icon: "plus-square",
            },
          ],
        },
      },
      {
        component: () => import("@/views/LiveSessionSliderForm.vue"),
        path: "/live-session-slider/:id?",
        name: "LiveSession",
        meta: {
          logged: true,
          title: "base.routes.livesessions.main",
        },
      }
    ],
  },


  {
    path: "/wall",
    name: "WallView",
    meta: {
      logged: true,
      title: "base.routes.wall.main",
      target: "WallList",
      icon: "check-circle",
    },
    children: [
      {
        component: () => import("@/views/WallList.vue"),
        path: "/walls",
        name: "WallList",
        meta: {
          logged: true,
          title: "base.routes.wall.list",
          buttons: [
            {
              to: "WallForm",
              title: "base.routes.wall.form",
              icon: "plus-square",
            },
          ],
        },
      },
      {
        component: () => import("@/views/WallForm.vue"),
        path: "/wall/:id?",
        name: "WallForm",
        meta: {
          logged: true,
          title: "base.routes.wall.form",
        },
      },
      // {
      //   component: () => import("@/views/DpProgramSectionsList.vue"),
      //   path: "/program/:id/sections",
      //   name: "SectionsList",
      //   meta: {
      //     logged: true,
      //     title: "base.routes.sections.list",
      //     buttons: [
      //       {
      //         to: "DpProgramSectionForm",
      //         title: "base.routes.programs.addSection",
      //         icon: "plus-square",
      //         params: {
      //           programid: ":id",
      //         },
      //       },
      //     ],
      //   }
      // },
      // {
      //   component: () => import("@/views/DpProgramSectionForm.vue"),
      //   path: "/program/:programid/section/:id?",
      //   name: "DpProgramSectionForm",
      //   props: true,
      //   meta: {
      //     logged: true,
      //     title: "base.routes.programs.section.form",
      //   },
      // },
      // {
      //   component: () => import("@/views/DpProgramModulesList.vue"),
      //   path: "/program/section/:id/modules",
      //   name: "ModulesList",
      //   meta: {
      //     logged: true,
      //     title: "base.routes.modules.list",
      //     buttons: [
      //       {
      //         to: "DpProgramModuleForm",
      //         title: "base.routes.programs.addModule",
      //         icon: "plus-square",
      //         params: {
      //           sectionid: ":id",
      //         },
      //       },
      //     ],
      //   },
      // },
      // {
      //   component: () => import("@/views/DpProgramModulesForm.vue"),
      //   path: "/program/section/:sectionid/module/:id?",
      //   name: "DpProgramModuleForm",
      //   props: true,
      //   meta: {
      //     logged: true,
      //     title: "base.routes.programs.module.form",
      //   },
      // },
      // {
      //   component: () => import("@/views/DpProgramGroupList.vue"),
      //   path: "/program/:id/groups",
      //   name: "GroupsList",
      //   meta: {
      //     logged: true,
      //     title: "base.routes.groups.list",
      //     buttons: [
      //       {
      //         to: "GroupsForm",
      //         title: "base.routes.programs.addGroup",
      //         icon: "plus-square",
      //         params: {
      //           programid: ":id",
      //         }
      //       },
      //     ],
      //   }
      // },
      // {
      //   component: () => import("@/views/DpProgramGroupForm.vue"),
      //   path: "/program/groups/:programid/:id?",
      //   name: "GroupsForm",
      //   props: true,
      //   meta: {
      //     logged: true,
      //     title: "base.routes.groups.form"
      //   }
      // },
      // {
      //   component: () => import("@/views/DpProgramGroupUsersList.vue"),
      //   path: "/program/:programid/:id/users",
      //   name: "GroupUsersList",
      //   meta: {
      //     logged: true,
      //     title: "base.routes.groups.list",
      //   }
      // },
      // {
      //   component: () => import("@/views/DpProgramGroupUserForm.vue"),
      //   path: "/program/group/:programid/:groupid/users/:id?",
      //   name: "DpProgramGroupUserForm",
      //   props: true,
      //   meta: {
      //     logged: true,
      //     title: "base.routes.programs.groups.form",
      //   },
      // },
      // {
      //   component: () => import("@/views/DpProgramHomeworksList.vue"),
      //   path: "/program/module/:moduleid/:id/homeworks",
      //   name: "ProgramHomeworks",
      //   meta: {
      //     logged: true,
      //     title: "base.routes.homeworks.list",
      //     buttons: [
      //       {
      //         to: "DPProgramHomeworkForm",
      //         title: "base.routes.programs.addHomework",
      //         icon: "plus-square",
      //         params: {
      //           moduleid: ":id",
      //           sectionid: ":moduleid"
      //         }
      //       },
      //     ],
      //   }
      // },
      // {
      //   component: () => import("@/views/DpProgramHomeworkForm.vue"),
      //   path: "/program/module/homework/:sectionid/:moduleid/:id?",
      //   name: "DPProgramHomeworkForm",
      //   props: true,
      //   meta: {
      //     logged: true,
      //     title: "base.routes.programs.section.form",
      //   },
      // },
      // {
      //   component: () => import("@/views/DpProgramPreworksList.vue"),
      //   path: "/program/module/:moduleid/:id/preworks",
      //   name: "ProgramPreworks",
      //   meta: {
      //     logged: true,
      //     title: "base.routes.preworks.list",
      //     buttons: [
      //       {
      //         to: "DpProgramPreworkForm",
      //         title: "base.routes.programs.addprework",
      //         icon: "plus-square",
      //         params: {
      //           moduleid: ":id",
      //           sectionid: ":moduleid"
      //         }
      //       },
      //     ],
      //   }
      // },
      // {
      //   component: () => import("@/views/DpProgramPreworkForm.vue"),
      //   path: "/program/module/:sectionid/:moduleid/prework/:id?",
      //   name: "DpProgramPreworkForm",
      //   props: true,
      //   meta: {
      //     logged: true,
      //     title: "base.routes.programs.section.form"
      //   },
      // },
      // {
      //   component: () => import("@/views/DpProgramAssessmentsList.vue"),
      //   path: "/program/module/:moduleid/:id/assessments",
      //   name: "ProgramAssessments",
      //   meta: {
      //     logged: true,
      //     title: "base.routes.assessments.list",
      //     buttons: [
      //       {
      //         to: "DpProgramAssessmentForm",
      //         title: "base.routes.programs.addassessment",
      //         icon: "plus-square",
      //       },
      //     ],
      //   }
      // },
      // {
      //   component: () => import("@/views/DpProgramAssessmentForm.vue"),
      //   path: "/program/module/assessment/:id?",
      //   name: "DpProgramAssessmentForm",
      //   meta: {
      //     logged: true,
      //     title: "base.routes.programs.section.form",
      //   },
      // },
      // {
      //   component: () => import("@/views/DpProgramAssessmentsCategoriesList.vue"),
      //   path: "/program/module/assessment/:id/categories",
      //   name: "DpProgramAssessmentsCategoriesList",
      //   meta: {
      //     logged: true,
      //     title: "base.routes.programs.section.form",
      //   },
      // },
      // {
      //   component: () => import("@/views/DpProgramAssessmentsQuestionsList.vue"),
      //   path: "/program/module/assessment/:id/questions",
      //   name: "DpProgramAssessmentsQuestionsList",
      //   meta: {
      //     logged: true,
      //     title: "base.routes.programs.section.form",
      //   },
      // },
      // {
      //   component: () => import("@/views/DpProgramLiveWidgetsList.vue"),
      //   path: "/program/module/:moduleid/:id/livewidgets",
      //   name: "ProgramLiveWidgets",
      //   meta: {
      //     logged: true,
      //     title: "base.routes.widgets.list",
      //     buttons: [
      //       {
      //         to: "LiveWidgetForm",
      //         title: "base.routes.programs.addLiveWebinar",
      //         icon: "plus-square",
      //         params: {
      //           moduleid: ":id",
      //           sectionid: ":moduleid"
      //         }
      //       },
      //     ],
      //   }
      // },
      // {
      //   component: () => import("@/views/DpProgramLiveWidgetsForm.vue"),
      //   path: "/program/module/livewidget/:sectionid/:moduleid/:id?",
      //   name: "LiveWidgetForm",
      //   props: true,
      //   meta: {
      //     logged: true,
      //     title: "base.routes.programs.section.form",
      //   },
      // },
      // {
      //   component: () => import("@/views/DpProgramPrecertificatesList.vue"),
      //   path: "/program/:id/precertificates",
      //   name: "PrecertificatesList",
      //   meta: {
      //     logged: true,
      //     title: "base.routes.precertificates.list",
      //     buttons: [
      //       {
      //         to: "PrecertificatesForm",
      //         title: "base.routes.programs.addPrecertificate",
      //         icon: "plus-square",
      //         params: {
      //           programid: ":id",
      //         }
      //       },
      //     ],
      //   }
      // },
      // {
      //   component: () => import("@/views/DpProgramPrecertificatesForm.vue"),
      //   path: "/program/precertificates/:programid/:id?",
      //   name: "PrecertificatesForm",
      //   props: true,
      //   meta: {
      //     logged: true,
      //     title: "base.routes.precertificates.form"
      //   }
      // },
      // {
      //   component: () => import("@/views/DpProgramScheduleList.vue"),
      //   path: "/program/module/:moduleid/:id/schedules",
      //   name: "SchedulesList",
      //   meta: {
      //     logged: true,
      //     title: "base.routes.schedules.list",
      //     buttons: [
      //       {
      //         to: "ScheduleForm",
      //         title: "base.routes.programs.addSchedule",
      //         icon: "plus-square",
      //         params: {
      //           moduleid: ":id",
      //           sectionid: ":moduleid"
      //         }
      //       },
      //     ],
      //   }
      // },
      // {
      //   component: () => import("@/views/DpProgramScheduleForm.vue"),
      //   path: "/program/module/schedule/:sectionid/:moduleid/:id?",
      //   name: "ScheduleForm",
      //   props: true,
      //   meta: {
      //     logged: true,
      //     title: "base.routes.programs.section.form",
      //   },
      // },
      // {
      //   component: () => import("@/views/DpReflectionsList.vue"),
      //   path: "/program/module/:moduleid/:id/reflections",
      //   name: "ReflectionsList",
      //   meta: {
      //     logged: true,
      //     title: "base.routes.schedules.list",
      //     buttons: [
      //       {
      //         to: "ReflectionForm",
      //         title: "base.routes.programs.addReflection",
      //         icon: "plus-square",
      //         params: {
      //           moduleid: ":id",
      //           sectionid: ":moduleid"
      //         }
      //       },
      //     ],
      //   }
      // },
      // {
      //   component: () => import("@/views/DpReflectionForm.vue"),
      //   path: "/program/module/reflections/:sectionid/:moduleid/:id?",
      //   name: "ReflectionForm",
      //   props: true,
      //   meta: {
      //     logged: true,
      //     title: "base.routes.reflections.form",
      //   },
      // },
      // {
      //   component: () => import("@/views/DpProgramTemplatesList.vue"),
      //   path: "/program/module/:moduleid/:id/templates",
      //   name: "TemplatesList",
      //   meta: {
      //     logged: true,
      //     title: "base.routes.schedules.list",
      //     buttons: [
      //       {
      //         to: "TemplatesForm",
      //         title: "base.routes.programs.addTemplate",
      //         icon: "plus-square",
      //         params: {
      //           moduleid: ":id",
      //           sectionid: ":moduleid"
      //         }
      //       },
      //     ],
      //   }
      // },
      // {
      //   component: () => import("@/views/DpProgramTemplatesForm.vue"),
      //   path: "/program/module/templates/:sectionid/:moduleid/:id?",
      //   name: "TemplatesForm",
      //   props: true,
      //   meta: {
      //     logged: true,
      //     title: "base.routes.murals.form",
      //   },
      // },
      // {
      //   component: () => import("@/views/DpProgramModuleFileForm.vue"),
      //   path: "/program/module/materials/:moduleid/:id?",
      //   name: "ModuleFileForm",
      //   props: true,
      //   meta: {
      //     logged: true,
      //     title: "base.routes.reflections.form",
      //   },
      // },
      // {
      //   component: () => import("@/views/DpProgramModuleFilesList.vue"),
      //   path: "/program/module/:moduleid/:id/materials",
      //   name: "FilesList",
      //   meta: {
      //     logged: true,
      //     title: "base.routes.materials.list",
      //   }
      // },
    ],
  },

  //hero slider
  {
    path: "/hero-slider",
    name: "HeroView",
    meta: {
      logged: true,
      title: "base.routes.hero.main",
      target: "Hero",
      icon: "home",
    },
    children: [
      {
        component: () => import("@/views/Hero.vue"),
        path: "/hero-slider",
        name: "Hero",
        meta: {
          logged: true,
          title: "base.routes.hero.list",
          buttons: [
            {
              to: "HeroForm",
              title: "base.routes.hero.form",
              icon: "plus-square",
            },
          ],
        },
      },
      {
        component: () => import("@/views/HeroForm.vue"),
        path: "/hero-slider/form/:id?",
        name: "HeroForm",
        props: true,
        meta: {
          logged: true,
          title: "base.routes.hero.form",
        },
      },
    ],
  },

  // {
  //   component: () => import("@/views/Hero.vue"),
  //   path: "/settings",
  //   name: "SettingsView",
  //   meta: {
  //     logged: true,
  //     title: "base.routes.settings.main",
  //     icon: "settings",
  //     target: "Hero",
  //     submenu: true,
  //   },
  //   children: [
  //     {
  //       component: () => import("@/views/Hero.vue"),
  //       path: "hero",
  //       name: "Hero slider",
  //       meta: {
  //         logged: true,
  //         title: "base.routes.settings.hero",
  //         icon: "home",
  //       },
  //     },
      // {
      //   component: () => import("@/views/Slider.vue"),
      //   path: "slider",
      //   name: "Slider",
      //   meta: {
      //     logged: true,
      //     title: "base.routes.settings.slider",
      //     icon: "sliders",
      //   },
      // },
      // {
      //   component: () => import("@/views/Searcher.vue"),
      //   path: "searcher",
      //   name: "Searcher",
      //   meta: {
      //     logged: true,
      //     title: "base.routes.settings.searcher",
      //     icon: "search",
      //   },
      // },
  //   ],
  // },
  //live slider
  {
    path: "/live-sessions-slider",
    name: "LiveSessionView",
    meta: {
      logged: true,
      title: "base.routes.livesessions.main",
      target: "LiveSession",
      icon: "home",
    },
    children: [
      {
        component: () => import("@/views/LiveSessionSliderForm.vue"),
        path: "/live-sessions-slider",
        name: "LiveSession",
        meta: {
          logged: true,
          title: "base.routes.livesessions.main",
        },
      }
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
